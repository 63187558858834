<template>
  <div
    class="flex pt-4 border-b border-gray-300 last:border-0 pb-4 hover:cursor-pointer"
    @click="$emit('click-card')"
  >
    <div class="flex-row flex-1">
      <div class="flex">
        <div class="flex items-start gap-x-3">
          <p class="text-sm font-semibold leading-6 text-gray-900">
            {{ card.name }}
          </p>
          <p
            :class="[
              statuses[cardStatus],
              'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium'
            ]"
          >
            {{ cardStatus }}
          </p>
        </div>
      </div>

      <div v-if="showDescription" class="text-sm flex-1">
        {{ cardDescription }}
      </div>
      <div class="flex w-full content-between mt-2">
        <div class="flex-1">
          <template v-if="showRemaining">
            <template v-if="card.cp_details.remaining === 0">
              <div class="text-base font-semi">Geen strippen meer over</div>
            </template>
            <template
              v-else-if="card.cp_details.remaining > 0 && !cardIsExpired"
            >
              <div class="text-base font-semi">
                Nog {{ card.cp_details.remaining }} strippen over
              </div>
            </template>
            <template v-else>
              <div class="text-base font-semi">
                Strippenkaart is verlopen ({{ expiryDate }})
              </div>
            </template>
          </template>
          <template v-else>
            <div class="text-base font-semi">
              {{ $n(card.cost, 'currency') }}
            </div>
            <div class="text-xs text-gray-500">
              {{ card.details.times_to_use }} keer te gebruiken
            </div>
          </template>
        </div>
        <button
          v-if="showBuyButton"
          class="button button-primary button-sm button-icon ml-4"
          @click="() => gotoDetailPage(card.id)"
        >
          <icon-base><icon-cart /></icon-base>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from '@/components/IconBase'
import IconCart from '@/components/icons/IconCart'

export default {
  name: 'CardItem',
  components: { IconBase, IconCart },
  props: {
    card: {
      type: Object,
      required: true
    },
    showBuyButton: {
      type: Boolean,
      default: true
    },
    showRemaining: {
      type: Boolean,
      default: false
    },
    showDescription: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      statuses: {
        Actief: 'text-green-700 bg-green-50 border border-green-500',
        Verlopen: 'text-yellow-800 bg-yellow-50 border border-yellow-500'
      }
    }
  },
  computed: {
    cardDescription: function () {
      if (this.card.description && this.card.description.length > 60) {
        return this.card.description.slice(0, 60).concat(' ...')
      } else {
        return this.card.description
      }
    },
    cardIsExpired: function () {
      return (
        new Date(this.card.cp_details.valid_until) < new Date() ||
        this.card.cp_details.status === 'expired'
      )
    },
    expiryDate: function () {
      return new Date(this.card.cp_details.valid_until).toLocaleDateString()
    },
    cardStatus: function () {
      // switch on this.card.cp_details.status
      if (
        this.card.cp_details.status === 'active' &&
        !this.cardIsExpired &&
        this.card.cp_details.remaining > 0
      ) {
        return 'Actief'
      } else {
        return 'Verlopen'
      }
    }
  },
  created: function () {},
  methods: {
    gotoDetailPage: function (id) {
      this.$router.push(`/cards/${id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>

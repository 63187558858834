<template>
  <div class="has-tab-bar">
    <app-bar
      :show-back-button="false"
      :show-profile-button="true"
      :title="activeTab === EVENTS ? 'Activiteiten' : 'Strippenkaarten'"
    />
    <template>
      <div class="container">
        <tab-navigation
          v-if="showTabNavigation"
          :tabs="tabs"
          :active-tab="activeTab"
          @toggle="changeTab"
        />
        <template v-if="activeTab === 'events'">
          <div class="flex m-0 py-4 justify-between space-x-4">
            <div class="w-1/2">
              <dropdown
                class="mb-4"
                :disabled="false"
                :items="availableCities"
                :selected="selectedCity"
                :empty-option="selectedCity ? 'Alle locaties' : 'Kies locatie'"
                theme="rounded"
                @on-select="
                  (val) => {
                    selectCity(val)
                    updateUrl()
                  }
                "
              />
            </div>
            <div class="w-1/2">
              <dropdown
                class="mb-4"
                :disabled="false"
                :items="activityList"
                :selected="selectedActivity"
                :empty-option="selectedActivity ? 'Alle sporten' : 'Kies sport'"
                theme="rounded"
                @on-select="
                  (val) => {
                    selectActivity(parseInt(val))
                    updateUrl()
                  }
                "
              />
            </div>
          </div>
          <hr class="my-3.5 border-gray-300" />
          <div class="wrapper-event-list">
            <event-item
              v-for="event in upcomingEvents"
              :key="event.dateId"
              :name="event.name"
              :activity-icon="event.activity_type.icon"
              :event-image="getEventImage(event)"
              :cost="event.costFeeIncluded"
              :start-time="event.startTime"
              :end-time="event.endTime"
              :date="event.date"
              :location="event.location ? event.location.name : 'undefined'"
              :maximum-participants="event.maximumParticipants"
              :number-of-participants="event.numberOfParticipants"
              :button-text="null"
              :cursor-pointer="true"
              :show-attendance="false"
              @click.native="
                routeToEventView(event.id, event.dateId, event.securityHash)
              "
            />
          </div>
          <infinite-loading
            v-if="isReady"
            :identifier="infiniteId"
            @infinite="infiniteHandler"
          >
            <span slot="no-more"></span>
            <span slot="no-results">
              <p class="text-sm mb-8">
                Er zijn geen events gevonden. Kies een andere sport of een
                andere locatie.
              </p>
            </span>
          </infinite-loading>
          <p class="my-3 text-center font-semibold">Niet wat je zoekt?</p>
          <a
            href="#"
            class="btn btn-green btn-block margin-bottom-15 btn-round"
            @click.prevent="routeToEventType"
          >
            organiseer zelf
          </a>
        </template>
        <template v-else-if="activeTab === CREDITCARDS">
          <cards-overview />
        </template>
      </div>
    </template>
    <tab-bar selected="search" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppBar from '@/components/AppBar'
import EventItem from '@/components/EventItem'
import TabBar from '@/components/TabBar'
import TabNavigation from '@/components/TabNavigation'
import CardsOverview from '@/views/cards/CardsOverview'
import data from '@/utils/data'
import { ACTIVITY_LIST } from '@/store/actions/activity'
import { EVENT_CREATE_RESET } from '@/store/actions/event'
import noImage from '@/assets/images/img_no-image.png'
import InfiniteLoading from 'vue-infinite-loading'
import { DATE_UPCOMING, DATE_UPCOMING_RESET } from '@/store/actions/date'
import Dropdown from '@/components/Dropdown'

const EVENTS = 'events'
const CREDITCARDS = 'creditcards'
const CITIES_WITH_URLS = [
  'utrecht',
  'arnhem',
  'rotterdam',
  'amsterdam',
  'ede',
  'amersfoort'
]

export default {
  name: 'EventsAndCardsOverview',
  components: {
    TabBar,
    EventItem,
    AppBar,
    InfiniteLoading,
    TabNavigation,
    CardsOverview,
    Dropdown
  },
  props: {
    city: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      showTabNavigation: false,
      EVENTS: EVENTS,
      CREDITCARDS: CREDITCARDS,
      dayList: data.daysOfWeek,
      noEventResult: false,
      infiniteId: +new Date(),
      activeTab:
        this.$route.name === 'CreditcardsOverview' ? CREDITCARDS : EVENTS,
      activity: this.$route.params.activity,
      selectedActivity: '',
      selectedCity: '',
      limit: 10,
      isReady: false
    }
  },
  computed: {
    ...mapState({
      activityList: (state) => state.activity.activityKeyValueList,
      eventStatus: (state) => state.event.status,
      dateStatus: (state) => state.date.status,
      upcomingEvents: (state) => state.date.upcoming.events,
      skip: (state) => state.date.upcoming.skip,
      availableCities: (state) =>
        state.date.upcoming.cities
          ? state.date.upcoming.cities.map((city) => ({
              key: city,
              value: city
            }))
          : []
    }),
    foundActivity: function () {
      const s = this.selectedActivity
      return (
        s &&
        this.activityList.find(
          (item) => item.value === s[0].toUpperCase() + s.substring(1)
        )
      )
    },
    loading: function () {
      return this.eventStatus === 'loading' || this.dateStatus === 'loading'
    },
    tabs: function () {
      return [
        { key: EVENTS, value: 'Activiteiten' },
        { key: CREDITCARDS, value: 'Strippenkaarten' }
      ]
    }
  },
  metaInfo() {
    return {
      title: `Alle activiteiten ${
        this.selectedCity ? `in ${this.selectedCity}` : ''
      } | Inviplay`
    }
  },
  watch: {
    $route(to) {
      this.activeTab = to.name === 'CreditcardsOverview' ? CREDITCARDS : EVENTS
    },
    selectedActivity() {
      this.$store.commit(DATE_UPCOMING_RESET)
      this.infiniteId += 1
    },
    selectedCity() {
      this.$store.commit(DATE_UPCOMING_RESET)
      this.infiniteId += 1
    },
    activityList() {
      if (this.activity && this.activityList.length) {
        this.selectActivity(this.activity)
        this.isReady = true
        this.updateUrl()
      }
    }
  },
  created: function () {
    if (!this.activityList.length) {
      this.$store.dispatch(ACTIVITY_LIST)
    }
    if (this.city) {
      this.selectCity(this.city)
    }
    if (this.activity && this.activityList.length) {
      this.selectActivity(this.activity)
    }
    if (this.activity && !this.activityList.length) {
      this.isReady = false
    } else {
      this.isReady = true
      this.updateUrl()
    }
  },
  methods: {
    routeToEventType: function () {
      this.$store.commit(EVENT_CREATE_RESET)
      this.$router.push('/events/new')
    },
    routeToEventView: function (id, dateId, securityHash) {
      this.$router.push(`/events/${dateId}/${securityHash}?origin=events`)
    },
    getActivityId: function (activity, list) {
      const parsedActivity = activity.replace(/-/g, ' ')
      const activityObj = list.find(
        (item) =>
          item.value ===
          parsedActivity[0].toUpperCase() + parsedActivity.substring(1)
      )
      return activityObj.key
    },
    getEventImage: function (event) {
      return event.image
        ? event.image
        : event.activity && event.activity.defaultImage
        ? event.activity.image
        : noImage
    },
    async infiniteHandler($state) {
      const upcoming = await this.$store.dispatch(DATE_UPCOMING, {
        skip: this.skip,
        limit: this.limit,
        city: this.selectedCity,
        activity: this.selectedActivity
      })
      if (upcoming.length) {
        $state.loaded()
      } else {
        $state.complete()
      }
    },
    changeTab(navItem) {
      this.$router.push(navItem === EVENTS ? 'events' : 'cards')
    },
    selectActivity(activity) {
      if (typeof activity === 'number' && !isNaN(activity)) {
        this.selectedActivity = activity
      } else {
        const activityObj = this.activityList.find(
          (item) => item.slug === activity
        )
        this.selectedActivity = activityObj ? activityObj.key : ''
      }
    },
    selectCity(city) {
      this.selectedCity = city
    },
    updateUrl() {
      const activityObj = this.activityList.find(
        (item) => item.key === this.selectedActivity
      )
      if (
        this.selectedCity &&
        CITIES_WITH_URLS.includes(this.selectedCity.toLowerCase())
      ) {
        this.$router.push(
          `/${this.selectedCity.toLowerCase()}/${
            activityObj ? activityObj.slug : ''
          }`
        )
      } else {
        this.$router.push(`/events/${activityObj ? activityObj.slug : ''}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.wrapper-location-select {
  top: 15px;
  position: fixed;
  z-index: 101;
  left: 50%;
  transform: translateX(calc(-50% - 15px));
  width: calc(100% - 85px);
  max-width: calc(640px - 85px);
}
.wrapper-switch-search-screen-filter-bar {
  float: right;
  width: 20%;
  height: 47px;
  text-align: right;
  align-self: center;
}
.wrapper-switch-search-screen-filter-bar a {
  color: $ipBlack;
  font-size: 1rem;
  font-weight: 500;
  display: inline-block;
  padding-top: 0;
}
.wrapper-switch-search-screen-filter-bar a span {
  display: inline-block;
  width: calc(100% - 30px);
  text-align: left;
}
.wrapper-switch-search-screen-filter-bar a button {
  display: inline-block;
  width: 25px;
  transform: translateY(calc(-50%));
}
.wrapper-filter-bar .wrapper-switch-search-screen a {
  color: $ipBlack;
  font-size: 0.688rem;
  font-weight: 500;
  display: inline-block;
  padding-top: 10px;
}
.wrapper-filter-bar .wrapper-switch-search-screen a span {
  display: inline-block;
  width: calc(100% - 30px);
  text-align: left;
}
.wrapper-filter-bar .wrapper-switch-search-screen a button {
  display: inline-block;
  width: 25px;
  transform: translateY(calc(-50%));
}
.text-nothing-found {
  color: $ipBlack;
  font-size: 0.9375rem;
  font-weight: 600;
  text-align: center;
}

@media all and (max-width: 575px) {
  .wrapper-switch-search-screen-filter-bar a {
    font-size: 0.688rem;
  }
  .wrapper-switch-search-screen-filter-bar a button {
    margin-top: 10px;
  }
}

@media all and (max-width: 320px) {
  .wrapper-switch-search-screen-filter-bar {
    height: 80px;
  }

  .wrapper-switch-search-screen-filter-bar a {
    font-size: 0.5rem;
  }

  .wrapper-switch-search-screen-filter-bar a span {
    margin-left: 2px;
    text-align: center;
    display: block;
  }

  .wrapper-switch-search-screen-filter-bar a button {
    display: block;
    margin-top: 20px;
  }
}

.container-event-type-selector {
  display: flex;
  margin: -10px 0 0 0;
}

/*}*/
</style>

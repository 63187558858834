<template>
  <button
    class="component-round-icon"
    :style="[
      isPressed || isActive ? activeWrapperStyle() : wrapperStyle(),
      isClickable ? { cursor: 'pointer' } : { cursor: 'default' }
    ]"
    @focus="onPress"
    @blur="onRelease"
  >
    <template v-if="secondIconClass !== undefined && secondIconClass !== null">
      <span class="center">
        <i
          :class="iconClass"
          :style="isPressed || isActive ? activeIconStyle() : iconStyle()"
        />
        <i
          :class="secondIconClass"
          :style="isPressed || isActive ? activeIconStyle() : iconStyle()"
        />
      </span>
    </template>
    <template v-else>
      <i
        class="center"
        :class="iconClass"
        :style="isPressed || isActive ? activeIconStyle() : iconStyle()"
      />
    </template>
  </button>
</template>

<script>
export default {
  name: 'RoundIcon',
  props: {
    iconClass: {
      type: String,
      required: false,
      default: 'fas fa-question'
    },
    secondIconClass: {
      type: String,
      required: false,
      default: null
    },
    iconColor: {
      type: String,
      required: false,
      default: '#000000'
    },
    backgroundColor: {
      type: String,
      required: false,
      default: '#FFFFFF'
    },
    size: {
      type: Number,
      required: false,
      default: 24
    },
    borderSize: {
      type: Number,
      required: false,
      default: 1
    },
    borderColor: {
      type: String,
      required: false,
      default: '#009B6D'
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false
    },
    isClickable: {
      type: Boolean,
      required: false,
      default: false
    },
    activeIconColor: {
      type: String,
      required: false,
      default: '#FFFFFF'
    },
    activeBackgroundColor: {
      type: String,
      required: false,
      default: '#009B6D'
    },
    activeBorderColor: {
      type: String,
      required: false,
      default: '#009B6D'
    }
  },
  data() {
    return {
      isPressed: false
    }
  },
  methods: {
    onPress() {
      if (this.isClickable) {
        this.isPressed = true
      }
    },
    onRelease() {
      if (this.isClickable) {
        this.isPressed = false
      }
    },
    wrapperStyle() {
      return {
        width: this.size + 'px',
        height: this.size + 'px',
        backgroundColor: this.backgroundColor,
        borderRadius: this.size + 'px',
        border: this.borderSize + 'px solid ' + this.borderColor
      }
    },
    iconStyle() {
      return {
        fontSize: this.size * 0.6 + 'px',
        color: this.iconColor
      }
    },
    activeWrapperStyle() {
      return Object.assign({}, this.wrapperStyle(), {
        backgroundColor: this.activeBackgroundColor,
        border: this.borderSize + 'px solid ' + this.activeBorderColor
      })
    },
    activeIconStyle() {
      return Object.assign({}, this.iconStyle(), {
        color: this.activeIconColor
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables';
.component-round-icon {
  display: inline-block;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  padding: 0;
  outline: 0;
}
.component-round-icon .center {
  position: absolute;
  top: 50%;
  left: 50%;
  vertical-align: middle;
  @include transform(translateX(-50%) translateY(-50%));
}
</style>

export default {
  daysOfWeek: [
    { key: 0, value: 'Zondag' },
    { key: 1, value: 'Maandag' },
    { key: 2, value: 'Dinsdag' },
    { key: 3, value: 'Woensdag' },
    { key: 4, value: 'Donderdag' },
    { key: 5, value: 'Vrijdag' },
    { key: 6, value: 'Zaterdag' },
    { key: 7, value: 'Zondag' }
  ],
  daysOfWeekShort: [
    { key: 0, value: 'Zo' },
    { key: 1, value: 'Ma' },
    { key: 2, value: 'Di' },
    { key: 3, value: 'Wo' },
    { key: 4, value: 'Do' },
    { key: 5, value: 'Vr' },
    { key: 6, value: 'Za' },
    { key: 7, value: 'Zo' }
  ],
  eventRecurringChoices: [
    { key: '7,day', value: 'Wekelijks' },
    { key: '14,day', value: 'Tweewekelijks' },
    { key: '1,month', value: 'Maandelijks' },
    { key: '6,month', value: 'Halfjaarlijks' },
    { key: '1,year', value: 'Jaarlijks' }
  ],
  genderChoices: [
    { key: 'male', value: 'man' },
    { key: 'female', value: 'vrouw' },
    { key: 'other', value: 'anders' }
  ],
  getYearOfBirthChoices() {
    const yearOfBirthChoices = []
    const yearCurrent = new Date().getFullYear()
    const yearEnd = yearCurrent - 112
    for (let year = yearCurrent; year > yearEnd; year--) {
      yearOfBirthChoices.push({ key: year.toString(), value: year.toString() })
    }
    return yearOfBirthChoices
  },
  rangeChoices: [
    // {key: '3', value: '3 km'},
    { key: '5', value: '5 km' },
    { key: '10', value: '10 km' },
    { key: '25', value: '25 km' },
    { key: '50', value: '50 km' },
    { key: 'all', value: 'Alle' }
  ],
  formatEventTypeItems: (item) => {
    switch (item) {
      case 'Holidaymaker':
        return 'Recreant'
      case 'Commercial':
        return 'Bedrijf'
      case 'Association':
        return 'Vereniging'
      default:
        return null
    }
  },
  formatRecurring: (recurring) => {
    if (recurring) {
      switch (recurring) {
        case '7,day':
          return 'wekelijks'
        case '14,day':
          return 'tweewekelijks'
        case '1,month':
          return 'maandelijks'
        case '6,month':
          return 'halfjaarlijks'
        case '1,year':
          return 'jaarlijks'
        default:
          return null
      }
    }
    return null
  }
}

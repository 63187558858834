<template>
  <div>
    <label v-if="label" for="selectbox" class="text-base font-semibold">
      {{ label }}
    </label>
    <div class="simple-select">
      <select
        v-model="selectedOption"
        class="border-gray-600 h-11"
        :class="theme === 'square' && 'square'"
        :disabled="disabled"
        @change="setChange"
      >
        <option v-if="emptyOption !== null" selected value="">
          {{ emptyOption }}
        </option>
        <option v-for="(item, index) in items" :key="index" :value="item.key">
          {{ item.value }}
        </option>
      </select>
      <div class="select-arrow" :style="{ borderTopColor: color }" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    items: {
      type: Array,
      required: true
    },
    selected: {
      type: [String, Number],
      default: ''
    },
    emptyOption: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: '#212529'
    },
    theme: {
      type: String,
      default: 'round'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      selectedOption: ''
    }
  },
  watch: {
    selected(selected) {
      this.selectedOption = selected
    }
  },
  created() {
    this.selectedOption = this.selected
    this.$emit('on-select', this.selectedOption)
  },
  mounted() {
    this.selectedOption = this.selected
  },
  methods: {
    setChange: function (event) {
      this.$emit('on-select', event.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables';

.simple-select {
  position: relative;
}
.simple-select {
  select {
    width: 100%;
    height: 50px;
    cursor: pointer;
    padding: 0.5rem 1rem;
    outline: 0;
    border: 1px solid $ipGreyLight;
    border-radius: 0.5rem;
    background: $ipWhite;
    color: $ipBlack;
    font-weight: 400;
    font-size: 0.875rem;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .select-arrow {
    top: 20px;
  }
}

.simple-select select.square {
  border: 2px solid $ipBlack;
  border-radius: 0;
  height: 50px;
  font-size: 1.25rem;
  color: #495057;
}

.simple-select select::-ms-expand {
  display: none;
}
.simple-select select:hover,
.simple-select select:focus {
}
.simple-select select:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.select-arrow {
  position: absolute;
  top: 20px;
  right: 1rem;
  width: 0;
  height: 0;
  pointer-events: none;
  border-style: solid;
  border-width: 6px 5px 0px 5px;
  border-color: $ipGreen transparent transparent transparent;
}
</style>

<template>
  <div class="pb-16">
    <SpinnerLoader v-if="loading"></SpinnerLoader>
    <div class="flex">
      <input-field size="small" class="flex-1 mr-4" />
      <button class="button button-secondary button-sm button-icon mb-6">
        <icon-base class="mr-2"><icon-filter /></icon-base>
        Filter
      </button>
    </div>
    <card-item v-for="card in cards" :key="card.id" :card="card" />
  </div>
</template>

<script>
import SpinnerLoader from '@/components/SpinnerLoader'
import { mapState } from 'vuex'
import { CREDITCARD_GET_ALL } from '@/store/actions/creditcard'
import CardItem from '@/components/CardItem'
import IconBase from '@/components/IconBase'
import IconFilter from '@/components/icons/IconFilter'
import InputField from '../../components/InputField.vue'

export default {
  name: 'CardsOverview',
  components: { SpinnerLoader, CardItem, IconBase, IconFilter, InputField },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState({
      cards: (state) => state.creditcard.all
    })
  },
  watch: {},
  created: function () {
    if (!this.cards.length) {
      this.$store.dispatch(CREDITCARD_GET_ALL)
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>

<template>
  <div>
    <label v-if="label" class="text-base font-semibold" :for="id">
      {{ label }}
    </label>
    <div class="relative">
      <input
        :id="id"
        :value="value"
        :type="type"
        :pattern="pattern"
        :placeholder="defaultValue"
        :inputmode="inputmode"
        class="px-4 py-2 border focus:ring-offset-green-600 focus:border-green-600 w-full text-sm border-gray-300 rounded-lg focus:outline-none text-gray-600"
        :class="[
          size === 'small' ? 'h-9' : 'h-12',
          currency ? 'pl-10' : 'pl-4'
        ]"
        @input="$emit('input', $event.target.value)"
        @blur="$emit('blur', $event.target.value)"
      />
      <div v-if="currency" class="absolute w-6 top-3 left-4 text-gray-600">
        {{ currency }}
      </div>
      <div
        v-if="clearIcon"
        class="absolute right-0 top-0 p-4"
        @click="$emit('clear')"
      >
        <icon-base><icon-x /></icon-base>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from '@/components/IconBase'
import IconX from '@/components/icons/IconX'

export default {
  name: 'InputField',
  components: { IconBase, IconX },
  props: {
    id: {
      type: [String, Number],
      default: undefined
    },
    defaultValue: {
      type: [String, Number],
      default: undefined
    },
    value: {
      type: [String, Number],
      default: undefined
    },
    label: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      default: 'text'
    },
    pattern: {
      type: String,
      default: undefined
    },
    inputmode: {
      type: String,
      default: 'text'
    },
    size: {
      type: String,
      default: 'base'
    },
    currency: {
      type: String,
      default: undefined
    },
    clearIcon: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({}),
  computed: {
    cValue: function () {
      return `€ ${this.value || this.defaultValue}`
    }
  },
  created: function () {},
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>

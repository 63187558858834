<template>
  <div class="has-tab-bar">
    <app-bar
      :show-back-button="false"
      :show-profile-button="true"
      title="Activiteiten"
    />
    <template>
      <div class="container">
        <div class="wrapper-filter-bar">
          <div class="wrapper-filter">
            <simple-select
              :items="activityList"
              :selected="selectedActivity"
              color="#000"
              action-on-change="activity"
              :empty-option="selectedActivity ? 'Alle sporten' : 'Kies sport'"
            />
          </div>
          <div class="wrapper-filter">
            <simple-select
              :items="availableCities"
              :selected="selectedCity"
              color="#000"
              action-on-change="location"
              :empty-option="selectedCity ? 'Alle locaties' : 'Kies locatie'"
            />
          </div>
        </div>
        <hr class="my-3.5 border-gray-300" />
        <div class="wrapper-event-list">
          <event-item
            v-for="event in upcomingEvents"
            :key="event.dateId"
            :name="event.name"
            :activity-icon="event.activity_type.icon"
            :event-image="getEventImage(event)"
            :cost="event.costFeeIncluded"
            :start-time="event.startTime"
            :end-time="event.endTime"
            :date="event.date"
            :location="event.location ? event.location.name : 'undefined'"
            :maximum-participants="event.maximumParticipants"
            :number-of-participants="event.numberOfParticipants"
            :button-text="null"
            :cursor-pointer="true"
            @click.native="
              routeToEventView(event.id, event.dateId, event.securityHash)
            "
          />
        </div>
        <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
          <span slot="no-more"></span>
          <span slot="no-results">
            <p v-if="!upcomingEvents.length" class="text-sm mb-8">
              Er zijn geen events gevonden. Kies een andere sport of een andere
              locatie.
            </p>
          </span>
        </infinite-loading>
        <p class="my-3 text-center font-semibold">Niet wat je zoekt?</p>
        <a
          href="#"
          class="btn btn-green btn-block margin-bottom-15 btn-round"
          @click.prevent="routeToEventType"
        >
          organiseer zelf
        </a>
      </div>
    </template>
    <tab-bar selected="search" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppBar from '@/components/AppBar'
import EventItem from '@/components/EventItem'
import TabBar from '@/components/TabBar'
import SimpleSelect from '@/components/SimpleSelect'
import data from '@/utils/data'
import { ACTIVITY_LIST } from '@/store/actions/activity'
import { EVENT_CREATE_RESET, EVENT_SET_FILTER } from '@/store/actions/event'
import noImage from '@/assets/images/img_no-image.png'
import InfiniteLoading from 'vue-infinite-loading'
import { DATE_UPCOMING, DATE_UPCOMING_RESET } from '@/store/actions/date'

export default {
  name: 'EventSearchList',
  components: {
    TabBar,
    EventItem,
    SimpleSelect,
    AppBar,
    InfiniteLoading
  },
  props: {
    city: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: () => ({
    dayList: data.daysOfWeek,
    noEventResult: false,
    infiniteId: +new Date()
  }),
  computed: {
    ...mapState({
      activityList: (state) => state.activity.activityKeyValueList,
      eventStatus: (state) => state.event.status,
      dateStatus: (state) => state.date.status,
      upcomingEvents: (state) => state.date.upcoming.events,
      availableCities: (state) =>
        state.date.upcoming.cities.map((city) => ({
          key: city,
          value: city
        })),
      skip: (state) => state.date.upcoming.skip,
      selectedActivity: (state) =>
        state.event.filterSelectedActivity.toString(),
      selectedDay: (state) => state.event.filterSelectedDay.toString(),
      selectedCity: (state) => state.event.filterSelectedLocation
    }),
    loading: function () {
      return this.eventStatus === 'loading' || this.dateStatus === 'loading'
    }
  },
  metaInfo() {
    return {
      title: `Alle activiteiten ${
        this.selectedCity ? `in ${this.selectedCity}` : ''
      } | Inviplay`
    }
  },
  watch: {
    selectedCity(city) {
      if (city === 'Utrecht') {
        this.$router.push('/utrecht')
      } else if (city === 'Arnhem') {
        this.$router.push('/arnhem')
      } else if (city === 'Rotterdam') {
        this.$router.push('/rotterdam')
      } else if (this.$router.currentRoute.path !== '/events') {
        this.$router.push('/events')
      }
      this.$store.commit(DATE_UPCOMING_RESET)
      this.infiniteId += 1
    },
    selectedActivity() {
      this.$store.commit(DATE_UPCOMING_RESET)
      this.infiniteId += 1
    }
  },
  created: function () {
    if (!this.activityList.length) {
      this.$store.dispatch(ACTIVITY_LIST)
    }
    if (this.city) {
      this.$store.dispatch(EVENT_SET_FILTER, {
        type: 'location',
        value: this.city
      })
    }
  },
  methods: {
    setActivityToFilter: function (activityID) {
      this.$store.dispatch(EVENT_SET_FILTER, {
        type: 'activity',
        value: activityID
      })
      this.$router.push('/events')
    },
    routeToEventType: function () {
      this.$store.commit(EVENT_CREATE_RESET)
      this.$router.push('/events/new')
    },
    routeToSearchMap: function () {
      this.$router.push('/search-map')
    },
    routeToEventView: function (id, dateId, securityHash) {
      this.$router.push(`/events/${dateId}/${securityHash}?origin=events`)
    },
    getEventImage: function (event) {
      return event.image
        ? event.image
        : event.activity && event.activity.defaultImage
        ? event.activity.image
        : noImage
    },
    async infiniteHandler($state) {
      const upcoming = await this.$store.dispatch(DATE_UPCOMING, {
        skip: this.skip,
        limit: 10,
        city: this.selectedCity,
        activity: this.selectedActivity
      })
      if (upcoming.length) {
        $state.loaded()
      } else {
        $state.complete()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.wrapper-location-select {
  top: 15px;
  position: fixed;
  z-index: 101;
  left: 50%;
  transform: translateX(calc(-50% - 15px));
  width: calc(100% - 85px);
  max-width: calc(640px - 85px);
}
.wrapper-filter-bar {
  display: flex;
  margin: 0;
  padding: 1rem 0;
  justify-content: space-between;
}
.wrapper-filter {
  width: calc(50% - 0.5rem);
}
.wrapper-switch-search-screen-filter-bar {
  float: right;
  width: 20%;
  height: 47px;
  text-align: right;
  align-self: center;
}
.wrapper-switch-search-screen-filter-bar a {
  color: $ipBlack;
  font-size: 1rem;
  font-weight: 500;
  display: inline-block;
  padding-top: 0;
}
.wrapper-switch-search-screen-filter-bar a span {
  display: inline-block;
  width: calc(100% - 30px);
  text-align: left;
}
.wrapper-switch-search-screen-filter-bar a button {
  display: inline-block;
  width: 25px;
  transform: translateY(calc(-50%));
}
.wrapper-filter-bar .wrapper-switch-search-screen a {
  color: $ipBlack;
  font-size: 0.688rem;
  font-weight: 500;
  display: inline-block;
  padding-top: 10px;
}
.wrapper-filter-bar .wrapper-switch-search-screen a span {
  display: inline-block;
  width: calc(100% - 30px);
  text-align: left;
}
.wrapper-filter-bar .wrapper-switch-search-screen a button {
  display: inline-block;
  width: 25px;
  transform: translateY(calc(-50%));
}
.text-nothing-found {
  color: $ipBlack;
  font-size: 0.9375rem;
  font-weight: 600;
  text-align: center;
}

@media all and (max-width: 575px) {
  .wrapper-switch-search-screen-filter-bar a {
    font-size: 0.688rem;
  }
  .wrapper-switch-search-screen-filter-bar a button {
    margin-top: 10px;
  }
}

@media all and (max-width: 320px) {
  .wrapper-switch-search-screen-filter-bar {
    height: 80px;
  }

  .wrapper-switch-search-screen-filter-bar a {
    font-size: 0.5rem;
  }

  .wrapper-switch-search-screen-filter-bar a span {
    margin-left: 2px;
    text-align: center;
    display: block;
  }

  .wrapper-switch-search-screen-filter-bar a button {
    display: block;
    margin-top: 20px;
  }
}

.container-event-type-selector {
  display: flex;
  margin: -10px 0 0 0;
}

/*}*/
</style>

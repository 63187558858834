<template>
  <div class="flex mt-4 mb-4">
    <div
      v-for="tab in tabs"
      :key="tab.key"
      class="w-1/2 pt-4 pb-2 align-middle text-center text-sm cursor-pointer border-b-2 border-gray-300 text-gray-300"
      :class="[
        activeTab === tab.key
          ? 'font-semibold text-green-600 border-green-600'
          : tab.disabled
          ? 'hover:cursor-default'
          : ''
      ]"
      @click="!tab.disabled && $emit('toggle', tab.key)"
    >
      {{ tab.value }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabNavigation',
  props: {
    tabs: {
      type: Array,
      required: true
    },
    activeTab: {
      type: String,
      required: true
    }
  }
}
</script>

<template>
  <div class="simple-select h-11">
    <select
      v-model="selectedOption"
      :style="{ color: color }"
      :class="theme === 'square' && 'square'"
      @change="setChange()"
    >
      <option v-if="emptyOption !== null" value="">
        {{ emptyOption }}
      </option>
      <option v-for="(item, index) in items" :key="index" :value="item.key">
        {{ item.value }}
      </option>
    </select>
    <div class="select-arrow" :style="{ borderTopColor: color }" />
  </div>
</template>

<script>
import { EVENT_SET_FILTER } from '@/store/actions/event'
export default {
  name: 'SimpleSelect',
  props: {
    items: {
      type: Array,
      required: true
    },
    selected: {
      type: String,
      required: false,
      default: ''
    },
    actionOnChange: {
      type: String,
      required: false,
      default: null
    },
    color: {
      type: String,
      required: false,
      default: '#009B6D'
    },
    emptyOption: {
      type: String,
      required: false,
      default: null
    },
    theme: {
      type: String,
      required: false,
      default: 'round'
    }
  },
  data() {
    return {
      selectedOption: this.selected
    }
  },
  methods: {
    setChange: function () {
      this.$store.dispatch(EVENT_SET_FILTER, {
        type: this.actionOnChange,
        value: this.selectedOption
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables';

.simple-select {
  position: relative;
}
.simple-select select {
  width: 100%;
  cursor: pointer;
  padding: 0.5rem 1.5rem 0.5rem 0.75rem;
  outline: 0;
  border: 1px solid $ipGreyLight;
  border-radius: 0.5rem;
  background: $ipWhite;
  color: $ipBlack;
  font-weight: 400;
  font-size: 1rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.simple-select select.square {
  border: 2px solid $ipBlack;
  border-radius: 0;
  height: 50px;
  font-size: 1.25rem;
  color: #495057;
}

.simple-select select::-ms-expand {
  display: none;
}
.simple-select select:hover,
.simple-select select:focus {
}
.simple-select select:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.select-arrow {
  position: absolute;
  top: 20px;
  right: 0.75rem;
  width: 0;
  height: 0;
  pointer-events: none;
  border-style: solid;
  border-width: 6px 5px 0px 5px;
  border-color: $ipGreen transparent transparent transparent;
}
</style>
